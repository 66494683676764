//import './App.css';
import React from 'react';


const PhraseCard = (props) => {
    const {phrase, translation, id} = props;
    return (
        <div className='bg-light-green dib br3 pa3 ma2 grow bw2 shadow5'>
        
        <div>
        <p>
        <b>{id}{phrase}</b>—
        {translation}</p>
        </div>
        </div>
    );

}

export default PhraseCard;